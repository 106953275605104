<template>
    <div>
        <table-primary caption>
            <table-row title="아이디" pointer>
                <v-text-field v-model="form.username" placeholder="아이디를 입력하세요." outlined hide-details @input="emit" />
            </table-row>
            <table-row title="이름" pointer>
                <v-text-field v-model="form.name" placeholder="이름을 입력하세요." outlined hide-details @input="emit" />
            </table-row>
            <table-row title="연락처" pointer>
                <v-phone-field v-model="form.phone" @input="emit" />
            </table-row>
            <table-row title="이메일" pointer>
                <v-email-field v-model="form.email" @input="emit" />
            </table-row>
            <table-row title="비밀번호" pointer>
                <v-text-field v-model="form.password" type="password" autocomplete="new-password" placeholder="영문,숫자,특수기호 포함 8자 입력해주세요." outlined hide-details @input="emit" />
            </table-row>
            <table-row title="비밀번호 확인" pointer>
                <v-text-field v-model="form.passwordConfirm" type="password" placeholder="동일한 비밀번호를 재입력하세요." outlined hide-details @input="emit" />
            </table-row>

            <!-- S::추가필드 -->
            <table-row title="보험증서">
                <v-file-input v-model="form.blacksmith.insurance.policies[0]" :clearable="false" placeholder="파일을 첨부하세요." outlined hide-details @change="emit">
                    <template #append>
                        <v-fade-transition leave-absolute> <v-icon v-show="form.blacksmith.insurance.policies[0]" @click="pullInsurancePolicy(0)">close</v-icon> </v-fade-transition>
                    </template>
                </v-file-input>
                <template v-if="form.blacksmith.insurance.policies[0]">
                    <v-file-input v-model="form.blacksmith.insurance.policies[1]" :clearable="false" placeholder="파일을 첨부하세요." outlined hide-details class="mt-10" @change="emit">
                        <template #append>
                            <v-fade-transition leave-absolute> <v-icon v-show="form.blacksmith.insurance.policies[1]" @click="pullInsurancePolicy(1)">close</v-icon> </v-fade-transition>
                        </template>
                    </v-file-input>
                </template>
                <template v-if="form.blacksmith.insurance.policies[1]">
                    <v-file-input v-model="form.blacksmith.insurance.policies[2]" :clearable="false" placeholder="파일을 첨부하세요." outlined hide-details class="mt-10" @change="emit">
                        <template #append>
                            <v-fade-transition leave-absolute> <v-icon v-show="form.blacksmith.insurance.policies[2]" @click="pullInsurancePolicy(2)">close</v-icon> </v-fade-transition>
                        </template>
                    </v-file-input>
                </template>
                <small>이미지 파일(JPG, PNG, GIF) PDF로 업로드 가능합니다.</small>
                <dialog-cert-example />
            </table-row>
            <table-row title="보험 만기일">
                <v-text-field v-model="form.blacksmith.insurance.expiresAt" type="date" placeholder="보험만기일을 선택하세요." outlined hide-details @input="emit" />
            </table-row>
            <table-row title="장비이용 동의서">
                <v-file-input v-model="form.blacksmith.equipmentUseConsent" placeholder="파일을 첨부하세요." outlined hide-details @change="emit" />
                <small>이미지 파일(JPG, PNG, GIF) PDF로 업로드 가능합니다.</small>
                <equipment-agreement-download />
            </table-row>
            <!-- E::추가필드 -->
        </table-primary>

        <!-- 하단버튼 -->
        <div class="v-btn--group">
            <v-row justify="center" class="row--x-small">
                <v-col cols="auto">
                    <v-btn large outlined rounded color="grey lighten-4" @click="$router.go(-1)">
                        <span class="grey--text">취소</span>
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn large rounded dark color="secondary" @click="join">
                        <span>가입하기</span>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { initUserIndividual } from "@/assets/variables";

import TableRow from "@/components/dumb/table-row.vue";
import TablePrimary from "@/components/dumb/table-primary.vue";

import DialogCertExample from "@/components/client/join/dialog-cert-example.vue";
import EquipmentAgreementDownload from "@/components/client/join/equipment-agreement-download.vue";

import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import VEmailField from "@/components/plugins/vuetify/v-email-field.vue";

export default {
    components: {
        TableRow,
        TablePrimary,

        DialogCertExample,
        EquipmentAgreementDownload,

        VPhoneField,
        VEmailField,
    },
    props: {
        value: { type: Object, default: initUserIndividual },
    },
    data: () => ({
        form: initUserIndividual(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initUserIndividual(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },

        join() {
            try {
                this.validate();
                this.$emit("submit", this.form);
            } catch (error) {
                alert(error.message);
            }
        },

        validate() {
            if (!this.form.username) throw new Error("아이디를 입력해주세요.");

            if (!this.form.name) throw new Error("이름을 입력해주세요.");
            if (!this.form.phone) throw new Error("휴대전화를 입력해주세요.");
            if (!this.form.email) throw new Error("이메일을 입력해주세요.");

            if (!this.form.password) throw new Error("비밀번호를 입력해주세요.");
            if (!this.checkPassword(this.form.password)) return false;
            if (!this.form.passwordConfirm) throw new Error("비밀번호 확인을 입력해주세요.");
            if (this.form.password != this.form.passwordConfirm) throw new Error("비밀번호를 확인해주세요");
        },

        checkPassword(password) {
            // var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            // if (!checkSpecial.test(password)) throw new Error("비밀번호에 특수문자가 포함되어있어야합니다.");
            if (!checkEng.test(password)) throw new Error("비밀번호에 영문이 포함되어있어야합니다.");
            if (!checkNum.test(password)) throw new Error("비밀번호에 숫자가 포함되어있어야합니다.");

            if (password.length < 8 || 16 < password.length) throw new Error("비밀번호는 8~16자까지입니다.");

            return true;
        },

        pullInsurancePolicy(index) {
            this.form.blacksmith.insurance.policies.splice(index, 1);
            this.emit();
        },
    },
};
</script>
